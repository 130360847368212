import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CargoType } from "src/app/core/interfaces/cargo-type.interface";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";

@Injectable({
  providedIn: "root"
})
export class CargoTypesService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getCargoTypes(unionId: number, cargoTypeId: number, employerId: number, skipCount: number, maxResultsCount: number) {
    const params = new HttpParams({
      fromObject: {
        unionId,
        cargoTypeId,
        employerId,
        MaxResultCount: maxResultsCount,
        SkipCount: skipCount
      }
    });
    return this.http.get<any>(`${this.apiUrl}/app/occCodeCargoType/groupList`, { params });
  }

  updateCargoType(occCodeIds: number[], cargoTypeId: number) {
    return this.http.post(`${this.apiUrl}/app/occCodeCargoType/toAddOrRemove`, { occCodeIds, cargoTypeId });
  }

  getCargoTypesByUnionAndEmployer(unionId: number, employerId: number) { 
    return this.http.get<any>(`${this.apiUrl}/app/union/${unionId}/cargoTypes/${employerId}`);
  }

  getCargoTypesByEmployer(employerId: number): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(`${this.apiUrl}/app/employer/${employerId}/masterCargoTypes`);
  }

}
